<template>
  <v-icon small @click="triggerToggleHelp"> mdi-help </v-icon>
</template>
<script>
export default {
  name: "TutorialTrigger",
  props: {
    tutorial: {
      type: String,
    },
  },
  methods: {
    triggerToggleHelp() {
      this.$store.dispatch("show_tutorial", this.tutorial);
    },
  },
};
</script>
